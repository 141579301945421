<template>
    <div>
        <button :id="id" type="button" :class="{'collapsible-header': true, open: isOpen}" :aria-expanded="isOpen" @click="toggleCollapsible">
            <slot name="header"></slot>
            <VJoyIcon name="chevron-down" class="chevron"></VJoyIcon>
        </button>
        <div ref="contentRef" role="region" :class="{'collapsible-content': true, open: isOpen}" :aria-labelledby="id">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script setup lang="ts">
    import type {Ref} from 'vue';
    import {ref} from 'vue';
    import {VJoyIcon} from '@maltjoy/core-vue';

    type CollapsibleProps = {
        id: string;
    };

    defineProps<CollapsibleProps>();

    const isOpen = ref(false);
    const contentRef: Ref<HTMLDivElement | null> = ref(null);

    function toggleCollapsible() {
        isOpen.value = !isOpen.value;

        if (contentRef.value) {
            if (isOpen.value) {
                contentRef.value.style.maxHeight = `${contentRef.value.scrollHeight}px`;
            } else {
                contentRef.value.style.maxHeight = '0px';
            }
        }
    }
</script>

<style lang="scss">
    @import '../scss/variables.scss';

    .collapsible-header {
        background-color: transparent;
        width: 100%;
        padding: 0;
        border: none;
        @include flex-row-align-center;
        justify-content: space-between;

        .chevron {
            transition: transform var(--joy-transition-duration-default) var(--joy-transition-timing-function);
            @include rotation(0deg);
        }

        &.open {
            .chevron {
                @include rotation(180deg);
            }
        }
    }

    .collapsible-content {
        transition: max-height var(--joy-transition-duration-long) var(--joy-transition-timing-function);
        max-height: 0;
        overflow: hidden;
    }
</style>
