<template>
    <section
        v-if="!(breakpoints?.isMobile && slice.primary.hideInMobile)"
        ref="section"
        :class="{'faq-section': true, narrow: slice.variation === 'noImage'}"
        aria-label="faq"
    >
        <PrismicRichText :field="slice.primary.title" class="title" />
        <PrismicRichText :field="slice.primary.description" class="description" />
        <OptimizedImage
            v-if="slice.variation !== 'noImage' && isFilled.image(slice.primary.image)"
            :prismic-image="slice.primary.image"
            image-class="image"
        />
        <slot name="header"></slot>
        <div
            :class="{
                'question-answer-wrapper': true,
                fullwidth: slice.variation === 'noImage' || JSON.stringify(slice.primary.image) === '{}',
            }"
        >
            <Collapsible v-for="(item, i) in slice.items" :id="asText(item.questionTitle)" :key="`slice-item-${i}`" class="item">
                <template #header>
                    <PrismicRichText :field="item.questionTitle" class="question-title" />
                </template>
                <template #content>
                    <PrismicRichText :field="item.answer" class="answer" />
                </template>
            </Collapsible>
        </div>
        <div class="link-container">
            <PrismicLink
                v-if="isLink(slice.primary.linkText, slice.primary.link)"
                :field="slice.primary.link"
                class="link"
                data-source-component="faq"
            >
                {{ slice.primary.linkText }}
            </PrismicLink>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {ref} from 'vue';
    import {getSliceComponentProps, PrismicRichText, PrismicLink} from '@prismicio/vue';
    import {asText, isFilled} from '@prismicio/helpers';
    import {usePrismicFieldHelpers} from '../../composables/shared/usePrismicFieldHelpers';
    import Collapsible from '../../components/Collapsible.vue';
    import OptimizedImage from '../../components/OptimizedImage.vue';
    import type {Content} from '@prismicio/client';
    import {useBreakpoints} from '../../composables/shared/useBreakpoints';
    import {useComponentTracking} from '#imports';

    defineProps({...getSliceComponentProps<Content.FaqSliceSlice>()});
    const {isLink} = usePrismicFieldHelpers();
    const breakpoints = useBreakpoints();
    const section = ref(null);

    useComponentTracking(section);
</script>

<style lang="scss">
    @import '../../scss/variables.scss';

    .faq-section {
        @include content-width;
        font-family: var(--joy-font-family);
        display: grid;
        grid-template-columns: 31.25% 1fr;
        column-gap: 110px;
        row-gap: 24px;
        margin-bottom: 120px;
        &.narrow {
            @include content-width(730px);
        }
        @media (max-width: $mobile-breakpoint) {
            @include flex-column-justify-center;
        }
        .title {
            grid-column-end: span 2;
            text-align: center;
            @media (max-width: $mobile-breakpoint) {
                text-align: left;
            }
            h2 {
                @include secondary-title;
            }
        }
        .description {
            font-size: var(--joy-font-size-primary-600);
            line-height: var(--joy-line-height-large);
            color: var(--joy-color-neutral-50);
            grid-column-end: span 2;
            text-align: center;
            @media (max-width: $mobile-breakpoint) {
                text-align: left;
            }
        }
        .link-container {
            grid-column-end: span 2;
            display: flex;
            justify-content: center;
            margin-top: 24px;

            @media (max-width: $mobile-breakpoint) {
                margin-top: 0;
            }
            .link {
                @include link;
            }
        }

        .image {
            margin-top: 64px;

            width: 100%;
            height: auto;
            object-fit: contain;
            object-position: top left;
            @media (max-width: $mobile-breakpoint) {
                order: 5;
                margin-top: 24px;
                padding: 0px 30px;
                box-sizing: border-box;
            }
        }
        .question-answer-wrapper {
            margin-top: 64px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 32px;
            &.fullwidth {
                grid-column-end: span 2;
            }
            @media (max-width: $mobile-breakpoint) {
                margin-top: 24px;
            }
        }

        .item {
            border-bottom: 1px solid var(--joy-color-neutral-30);
            padding-bottom: 5px;
            &:last-child {
                border: none;
            }
            > button {
                padding-bottom: var(--joy-core-spacing-6);
            }
            .question-title {
                h2,
                h3 {
                    font-family: var(--joy-font-family-title);
                    color: var(--joy-color-neutral-40);
                    font-size: var(--joy-font-size-primary-600);
                    text-align: left;
                }
            }

            .answer {
                margin: 0 100px var(--joy-core-spacing-7) 0;
                line-height: var(--joy-line-height-large);
                font-size: var(--joy-font-size-primary-400);
                color: var(--joy-color-neutral-50);

                h3 {
                    font-size: var(--joy-font-size-primary-400);
                }
                a,
                a:visited {
                    color: var(--joy-color-secondary-30);
                    font-weight: var(--joy-font-weight-bold);
                }
                a:hover {
                    text-decoration: underline;
                }
                p:not(:first-child) {
                    margin-top: var(--joy-core-spacing-4);
                }
                ul,
                ol {
                    margin-top: var(--joy-core-spacing-4);
                    margin-bottom: var(--joy-core-spacing-8);
                    margin-left: var(--joy-core-spacing-8);
                    margin-right: 0;
                }
            }
        }
    }
</style>
